import HttpRequest from '@/http/httpClass';
import {
  PRICE_COMPARISON,
  HOME,
  RESTAURANTS_PLATFORMS,
  CITIES_PLATFORMS,
  FUNCTIONS,
  INSIGHTS,
  MARKET,
} from '@/http/endpoints';
import { Session } from '@/globals';
import { routerInstance as router } from '@/routes/router';

import { Actions } from '../store';
import {
  ChangeRestaurantPlatformResponse,
  HomeResponse,
  InsightsResponse,
  CommonFiltersResponse,
  CityAndSourcesResponse,
  PriceComparisonFiltersResponse,
  SuggestedCompetitorsResponse,
  PriceMetricsResponse,
  PriceComparisonResponse,
  InsightType,
  InsightSettings,
  SavedCompetitorsResponse,
  PriceComparisonMoreFiltersResponse,
} from './actionsResponses';

import termsAndConditionsActions from './termsAndConditions/termsAndConditionsActions';
import settingsActions from './settings/settingsActions';

import { GetInsightsOptions } from '@/http/endpoints.types';

const actions: Actions = {
  ...termsAndConditionsActions,
  ...settingsActions,
  async login(_, options) {
    const { data, rememberMe } = options;
    const loginResponse = await HttpRequest.authenticate(data);

    const { accessToken, isSuperUser, idRestaurantSourceMenusdb, roles } = loginResponse;

    Session.setSession('pleez-token', accessToken, rememberMe);
    Session.setSession('pleez-persistent', true, rememberMe);
    Session.setSession('isSuperUser', isSuperUser, rememberMe);
    Session.setSession('pleez-roles', roles, rememberMe);
    Session.setSession(
      'pleez-id-restaurant-source-menus-db',
      idRestaurantSourceMenusdb,
      rememberMe,
    );

    return { isSuperUser: isSuperUser };
  },
  changeRestaurantPlatform({ commit, getters }, options) {
    const newPlatform = options.newPlatform;
    const platforms = getters.restaurantPlatforms;
    const restaurantPlatform = platforms.find(
      (platform: { id: number }) => platform.id === newPlatform,
    );

    if (restaurantPlatform) {
      const request = new HttpRequest(
        `/auth/renewToken/${restaurantPlatform.idRestaurantSourceMenusdb}`,
        'POST',
        true,
      );

      return request.send<ChangeRestaurantPlatformResponse>().then((response) => {
        Session.setSession('pleez-token', response.accessToken, false);
        Session.setSession('pleez-persistent', true, false);
        Session.setSession('pleez-selected-platform', newPlatform, false);
        Session.setSession(
          'pleez-id-restaurant-source-menus-db',
          response.idRestaurantSourceMenusdb,
          false,
        );

        commit('setPlatform', newPlatform);

        router.go(0);
      });
    }
  },
  getPleezInsights(
    { commit },
    options: GetInsightsOptions = {
      filter: {},
      sort: '',
      order: '',
      offset: 0,
      limit: 0,
    },
  ) {
    const request = new HttpRequest(INSIGHTS.ROOT(options), 'GET', true);

    return request.send<InsightsResponse>().then((response: InsightsResponse) => {
      commit('setPleezInsights', response.data);

      return response;
    });
  },
  getMenuChanges(_, options = { filter: {}, sort: '', order: '', offset: 0, limit: 0 }) {
    const request = new HttpRequest(FUNCTIONS.LIST_USER_FUNCTIONS(options), 'GET', true);

    return request.send();
  },
  updatePleezInsight(_, options) {
    const { id, approved } = options;

    const request = new HttpRequest(`/pleezInsights/${id}`, 'PUT', true, {
      approved,
    });

    return request.send();
  },
  getUserInvoices() {
    const request = new HttpRequest(`/payment/invoices?limit=5000`, 'GET', true);

    return request.send();
  },
  getHomePageDetails() {
    const request = new HttpRequest(HOME, 'GET', true);

    return request.send<HomeResponse>().then((response) => {
      return {
        timeSaver: {
          insights: response.time_saver?.insights,
          menuChanges: response.time_saver?.menu_changes,
        },
      };
    });
  },
  async getCommonFilters({ commit, state }) {
    const isNotEmpty = Object.values(state.commonFilters).some((arr) => arr.length > 0);

    if (isNotEmpty) return;

    const request = new HttpRequest(RESTAURANTS_PLATFORMS, 'GET', true);

    const response = await request.send<CommonFiltersResponse>();

    if (response) {
      commit('setCommonFilters', response);
    }
  },

  async getCityAndPlatforms({ commit, state }) {
    const isNotEmpty = Object.values(state.cityAndPlatforms).some((arr) => arr.length > 0);

    if (isNotEmpty) return;

    const request = new HttpRequest(CITIES_PLATFORMS, 'GET', true);

    const response = await request.send<CityAndSourcesResponse>();

    const { sources: platforms, cities, defaultCity } = response;

    if (response) {
      commit('setCityAndPlatforms', {
        platforms,
        cities,
        defaultCity,
      });
    }
  },
  async getPriceComparisonCompetitorFilters({ commit }) {
    const request = new HttpRequest(PRICE_COMPARISON.FILTERS, 'GET', true);

    const response = await request.send<PriceComparisonFiltersResponse>();

    const { sources, defaultSource, allRestaurants, defaultRestaurant } = response;

    if (response) {
      commit('setPriceComparisonCompetitorFilters', {
        sources,
        defaultSource,
        allRestaurants,
        defaultRestaurant,
      });
    }
  },
  getPriceComparisonCompetitorMoreFilters(_, filter = {}) {
    const request = new HttpRequest(PRICE_COMPARISON.MORE_FILTERS(filter), 'GET', true);

    return request.send<PriceComparisonMoreFiltersResponse>();
  },
  getSuggestedCompetitors(_, filter = {}) {
    const request = new HttpRequest(PRICE_COMPARISON.SUGGESTED_COMPETITORS(filter), 'GET', true);

    return request.send<SuggestedCompetitorsResponse>();
  },
  getSavedCompetitors(_, filter = {}) {
    const request = new HttpRequest(
      PRICE_COMPARISON.SUGGESTED_COMPETITORS_SAVED(filter),
      'GET',
      true,
    );

    return request.send<SavedCompetitorsResponse>();
  },
  getPriceMetricsForRegion(_, filter) {
    const request = new HttpRequest(PRICE_COMPARISON.PRICE_METRICS_REGION(filter), 'GET', true);

    return request.send<PriceMetricsResponse>();
  },
  getPriceMetricsForCompetitors(_, filter) {
    const request = new HttpRequest(
      PRICE_COMPARISON.PRICE_METRICS_COMPETITORS(filter),
      'GET',
      true,
    );

    return request.send<PriceMetricsResponse>();
  },
  getPriceComparisonData(_, { filter, sort, offset = 0, limit = 25, search }) {
    const searchParams = new URLSearchParams({
      platform: filter?.platformId?.[0]?.toString() ?? '',
      offset: offset.toString(),
      limit: limit.toString(),
    });

    filter.restaurantIds.forEach((restaurant) =>
      searchParams.append('restaurants', restaurant.toString()),
    );

    filter.categories?.forEach((category) => {
      searchParams.append('category', category.toString());
    });

    filter.idItems?.forEach((item) => {
      searchParams.append('items', item.toString());
    });

    if (sort) {
      searchParams.append('sort', `${sort.key}:${sort.direction}`);
    }
    if (search) {
      searchParams.append('search', `title:${search}`);
    }

    const client = new HttpRequest(PRICE_COMPARISON.ROOT(searchParams), 'GET', true);
    return client.send<PriceComparisonResponse>();
  },
  getInsightTypes() {
    const client = new HttpRequest(INSIGHTS.TYPES, 'GET', true);
    return client.send<InsightType[]>();
  },
  getInsightSettings() {
    const client = new HttpRequest(INSIGHTS.SETTINGS, 'GET', true);
    return client.send<InsightSettings[]>();
  },
  saveInsightSettings(_, insightSettings) {
    const client = new HttpRequest(INSIGHTS.SETTINGS, 'PUT', true, insightSettings);
    return client.send();
  },
  getPromotionTypes() {
    const promotionsResponse = new HttpRequest(MARKET.PROMOTIONS_TYPES, 'GET', true);
    return promotionsResponse.send();
  },
  async getPromotionsTypes() {
    const types = new HttpRequest(MARKET.PROMOTIONS_TYPES, 'GET', true);
    return types.send();
  },
};

export default actions;
