<template>
  <button
    :class="[
      'action-button',
      `action-button--${actionColorType}`,
      {
        'action-button--filled': isFilled,
        'action-button--disabled': isDisabled,
        'action-button--short': hideLabel,
      },
    ]"
    @click="$emit('handleClick')"
  >
    <div class="action-button__icon">
      <ApproveIcon v-if="actionColorType === 'approved'" />
      <DisapproveIcon v-else />
    </div>
    <p v-if="!hideLabel" class="action-button__text">
      <template v-if="label"> {{ label }} </template>
      <template v-else>
        {{ $t(actionLabel) }}
      </template>
    </p>
  </button>
</template>

<script lang="ts">
// TODO: Update this component to support more than just insights, should be more generic
import { InsightStatus } from '@/store/v1/store';
import { PropType, defineComponent } from 'vue';

export default defineComponent({
  props: {
    actionColorType: {
      type: String as PropType<InsightStatus>,
      default: 'approved',
    },
    isFilled: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: false,
    },
  },
  computed: {
    actionLabel(): string {
      switch (this.actionColorType) {
        case 'approved':
          if (this.isDisabled) {
            return 'pleezInsights.approved';
          } else {
            return 'pleezInsights.approve';
          }

        case 'rejected':
          if (this.isDisabled) {
            return 'pleezInsights.rejected';
          } else {
            return 'pleezInsights.reject';
          }

        case 'expired':
          return 'pleezInsights.expired';

        default:
          console.error('Unsupported status');
          return '';
      }
    },
  },
});
</script>

<style lang="scss">
.action-button {
  @include brand-font-m;

  --action-button-min-width: 116px;

  display: flex;
  justify-content: center;
  align-items: center;

  min-width: var(--action-button-min-width);
  height: var(--spacing-m);
  border-radius: var(--spacing-3xs);
  color: var(--secondary-text);

  background-color: #f2f3f7;

  transition: opacity 0.2s ease-in-out;

  font-weight: 300;

  cursor: pointer;

  &__text {
    margin-left: var(--spacing-3xs);
    margin-right: var(--spacing-xxs);

    text-align: left;
  }

  &--approved,
  &--rejected {
    &:not(&--disabled):hover {
      opacity: 0.8;
    }
  }

  &--filled {
    &.action-button--approved {
      color: var(--color-positive);
      background-color: rgba(var(--color-positive-rgb), 0.1);
    }

    &.action-button--rejected {
      color: var(--color-refuse);
      background-color: rgba(var(--color-refuse-rgb), 0.1);
    }
  }

  &--disabled {
    cursor: not-allowed;
    pointer-events: none;

    .action-button__icon svg rect {
      fill: var(--color-disabled);
    }

    .action-button__icon svg path {
      stroke: var(--secondary-text);
    }
  }

  &--short {
    --action-button-min-width: 2rem;
  }
}
</style>
